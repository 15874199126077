import { defineComponent } from '@vue/composition-api';

export default defineComponent({
	name: 'MusicSongListPage',
	props: {
		count: {
			type: Number,
			required: true,
		},
		current: {
			type: Number,
			required: false,
		},
	},
	setup(props, { root }) {
		const { $route } = root;
		let { count: countVal, current: currentVal } = props;
		function toNum(val: any, defaultVal?: number): number {
			if (typeof val !== 'number') {
				val = parseInt('' + val);
			}
			if (isNaN(val)) {
				val = defaultVal ? (!isNaN(defaultVal) ? defaultVal : 0) : 0;
			}
			return val;
		}
		countVal = toNum(countVal, 1);
		currentVal = toNum(currentVal, Number($route.query.listpage || 0));
		console.log('current', currentVal);
		return { countVal, currentVal };
	},
});
