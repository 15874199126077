import { defineComponent, Ref, ref } from '@vue/composition-api';
import Card from '@/components/Card';
import FetchData from '@/components/FetchData';
import MusicSongList from '@/components/Article/MusicSongList';
import MusicSongListPage from '@/components/Article/MusicSongListPage';

type SoN = string | number;
interface InnerTagT {
	category: string;
	onlyid: SoN;
	tagName: string;
}
interface AllTagT {
	onlyid: SoN;
	categoryName: string;
	children: InnerTagT[];
}

export default defineComponent({
	components: { Card, FetchData, MusicSongList, MusicSongListPage },
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const allTag: Ref<AllTagT[]> = ref([]);
		const touchAllTagData = (data: AllTagT[]) => {
			allTag.value = data;
			//console.log(data);
		};
		const musicListData: Ref<any> = ref([
			{
				onlyid: '2',
				title: '歌曲占位符',
				author: [
					'未知1',
					{
						onlyid: 'asudq23jhasdqw',
						title: '未知2',
					},
				],
				duration: 327.01,
			},
			{
				onlyid: '3',
				title: '无法播放的歌曲',
				author: '未知歌手',
				duration: 327.01,
				deterplay: true,
			},
		]);
		const touchMusicListData = (data: any) => {
			musicListData.value = data;
			//console.log(data);
		};
		return { allTag, touchAllTagData, musicListData, touchMusicListData, t };
	},
});
